import { cn } from "@/lib/library";
import { ShoppingCart } from "lucide-react";

interface OpenCartProps {
    className?: string;
    quantity?: number;
}

const OpenCart = ({ className, quantity }: OpenCartProps) => {
    return (
        <div className="relative flex h-11 w-11 items-center justify-center rounded-md border border-input bg-background transition-colors hover:bg-accent hover:text-accent-foreground">
            <ShoppingCart
                className={cn("h-4 w-4 transition-all ease-in-out hover:scale-110", className)}
            />

            {quantity ? (
                <div className="bg-cart absolute -right-2.5 -top-2.5 flex h-5 w-5 items-center justify-center rounded-full p-0 text-[12px] font-medium text-white">
                    {quantity}
                </div>
            ) : null}
        </div>
    );
};

export default OpenCart;
