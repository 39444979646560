"use client";

import { Button } from "@/components/shared/ui/button";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "@/components/shared/ui/sheet";
import { useCurrentCustomer } from "@/state-management/context/current-customer-context";
import { useCartStore } from "@/state-management/stores/cart-store";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { memo, useEffect, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import TransactionsListWithHeader from "../cart-edit/transactions-list-with-header";
import { findOrCreateCartAndSetCookie } from "./actions";
import OpenCart from "./open-cart";

const CartHeader = memo(function CartHeader({
    customerName,
    customerID
}: {
    customerName?: string;
    customerID?: number;
}) {
    return (
        <div className="flex items-center justify-between pb-4">
            <p className="text-lg font-semibold">
                Cart for ({customerID}) {customerName}
            </p>
            <SheetClose asChild>
                <button className="relative flex h-11 w-11 items-center justify-center rounded-md border border-neutral-200 text-black transition-colors dark:border-neutral-700 dark:text-white">
                    <XMarkIcon />
                </button>
            </SheetClose>
        </div>
    );
});

const EmptyCartMessage = memo(function EmptyCartMessage() {
    return <p className="mt-6 text-center text-2xl font-bold">Your cart is empty.</p>;
});

const CartTotal = memo(function CartTotal({ total }: { total: number }) {
    const formattedTotal = useMemo(() => {
        return new Intl.NumberFormat(undefined, {
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol"
        }).format(total);
    }, [total]);

    return (
        <div className="mb-3 flex items-center justify-between border-t border-neutral-200 pb-1 pt-3 dark:border-neutral-700">
            <p>Total</p>
            <p>{formattedTotal}</p>
        </div>
    );
});

export default function CartModal() {
    const { customer } = useCurrentCustomer();

    const { cart, skuCount } = useCartStore(
        useShallow((state) => ({
            cart: state.cart,
            skuCount: state.cart ? new Set(state.cart.transactions.map((t) => t.productID)).size : 0
        }))
    );

    useEffect(() => {
        if (!cart) {
            findOrCreateCartAndSetCookie();
        }
    }, [cart]);

    return (
        <Sheet>
            <SheetTrigger asChild>
                <button aria-label="Open cart">
                    <OpenCart quantity={skuCount} />
                </button>
            </SheetTrigger>
            <SheetContent
                side="right"
                className="w-full border-l border-neutral-200 p-0 dark:border-neutral-700 md:w-1/2 md:max-w-[800px] [&>button:first-child]:hidden"
            >
                <div className="flex h-full flex-col">
                    <div className="p-6">
                        <CartHeader
                            customerName={customer?.customerName}
                            customerID={customer?.customerID}
                        />
                    </div>

                    <div className="flex-1 overflow-y-auto px-2">
                        {!cart || cart.transactions.length === 0 ? (
                            <EmptyCartMessage />
                        ) : (
                            <TransactionsListWithHeader
                                transactions={cart.transactions}
                                productPromotions={cart.promotionsProducts || null}
                            />
                        )}
                    </div>

                    <div className="bg-white px-6 pb-5 dark:bg-black">
                        <CartTotal total={cart?.totalExtPrice ?? 0} />
                        <SheetTrigger asChild>
                            <Link href="/checkout">
                                <Button
                                    className="block w-full p-3 text-center text-sm font-medium text-white opacity-90 hover:opacity-100"
                                    type="button"
                                >
                                    Checkout
                                </Button>
                            </Link>
                        </SheetTrigger>
                    </div>
                </div>
            </SheetContent>
        </Sheet>
    );
}
