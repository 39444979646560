"use client";

import { ShopCustomer } from "@/lib/customers/customer-types";
import { ReactNode, createContext, useContext, useState } from "react";

interface CurrentCustomerContextType {
    customer: ShopCustomer | null;
    // eslint-disable-next-line no-unused-vars
    setCurrentCustomer: (customer: ShopCustomer | null) => void;
}

const CurrentCustomerContext = createContext<CurrentCustomerContextType | null>(null);

export const useCurrentCustomer = (): CurrentCustomerContextType => {
    const context = useContext(CurrentCustomerContext);
    if (!context) {
        throw new Error("useCurrentCustomer must be used within a CustomerProvider");
    }
    return context;
};

export const CurrentCustomerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [customer, setCurrentCustomer] = useState<ShopCustomer | null>(null);

    return (
        <CurrentCustomerContext.Provider value={{ customer, setCurrentCustomer }}>
            {children}
        </CurrentCustomerContext.Provider>
    );
};
