"use client";
import { Button } from "@/components/shared/ui/button";
import { ContactInfoCard } from "@/components/shared/ui/contact-info-card";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/shared/ui/popover";
import { CustomerContactList } from "@/lib/nav/nav-types";
import { ChevronDown, ContactRound, ExternalLink } from "lucide-react";
import { useState } from "react";

export default function ContactMyReps({
    customerContactList
}: {
    customerContactList: CustomerContactList;
}) {
    const [isOpen, setIsOpen] = useState(false);

    const kssContact = customerContactList.kssContact;
    if (!kssContact) return;

    const supplierContactDetails = customerContactList.supplierContacts.map((rep) => (
        <div key={rep.supplier} className="border-t px-4 py-2">
            <ContactInfoCard contactInfo={rep} />
        </div>
    ));

    return (
        <div className="flex flex-col">
            <Popover open={isOpen} onOpenChange={setIsOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="ghost"
                        className="mr-2 flex w-fit flex-row gap-1.5 p-2 hover:bg-gray-100 sm:mr-0"
                        size="lg"
                    >
                        <ContactRound className="h-4 w-4" />
                        <span>Contact My Reps</span>
                        <ChevronDown
                            className={`h-4 w-4 transition-transform ${isOpen ? "rotate-180" : ""}`}
                            aria-hidden="true"
                        />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="mx-4 my-0 h-auto w-auto p-0">
                    <div className="flex max-h-[65vh] w-fit max-w-[90vw] flex-col overflow-hidden">
                        <div className="flex-none px-4 pt-2 text-lg font-semibold">
                            KSS Territory Manager
                        </div>
                        <div className="flex flex-none flex-row px-4 pb-2 pt-1">
                            {kssContact.email && kssContact.name ? (
                                <ContactInfoCard contactInfo={kssContact} />
                            ) : (
                                <span>A sales rep has not yet been assigned to your account</span>
                            )}
                        </div>
                        {supplierContactDetails.length > 0 && (
                            <>
                                <div className="text-md flex-none py-2 pl-4 font-semibold">
                                    Brand Sales Reps
                                </div>
                                <div className="flex-auto overflow-y-auto">
                                    {supplierContactDetails}
                                </div>
                            </>
                        )}
                        <div className="border-t p-1">
                            <a
                                href="https://forms.gle/egzDaQvpfbyJDGV2A"
                                className="flex h-full w-full flex-row items-center justify-center gap-2 hover:text-blue-600"
                                target="_blank"
                            >
                                <span>Leave us your feedback</span>
                                <ExternalLink className="h-4 w-4" />
                            </a>
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
}
