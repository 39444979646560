"use client";

import { CART_ID_COOKIE_NAME } from "@/lib/constants";
import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

export default function ClientSessionMonitor() {
    const { data: session } = useSession();
    const router = useRouter();

    useEffect(() => {
        if (!session?.expires) return;

        const expiresAt = new Date(session.expires).getTime();
        const currentTime = new Date().getTime();
        const timeUntilExpiry = expiresAt - currentTime;

        if (timeUntilExpiry <= 0) {
            handleSessionEnd(router);
            return;
        }

        const timeoutId = setTimeout(() => {
            handleSessionEnd(router);
        }, timeUntilExpiry + 1000); // Add 1 second buffer

        return () => clearTimeout(timeoutId);
    }, [session, router]);

    return null;
}

function handleSessionEnd(router: ReturnType<typeof useRouter>) {
    document.cookie = `${CART_ID_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    router.refresh();
}
