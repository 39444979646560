"use client";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/shared/ui/avatar";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "@/components/shared/ui/dropdown-menu";
import { UserIcon } from "lucide-react";
import Logout from "./logout";

interface UserIconProps {
    imageUrl?: string;
}

export default function UserIconMenu({ imageUrl }: UserIconProps) {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger className="ml-4">
                <Avatar>
                    <AvatarImage src={imageUrl} />
                    <AvatarFallback>
                        <UserIcon className="h-5 w-5" />
                    </AvatarFallback>
                </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="items-center text-center">
                {/* <DropdownMenuItem asChild>
                    <Link href="/account">Account</Link>
                </DropdownMenuItem> */}
                <DropdownMenuItem>
                    <Logout className="underline" />
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
