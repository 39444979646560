"use client";

import { signOut } from "next-auth/react";

export default function Logout({ className }: { className?: string }) {
    return (
        <div
            aria-label="Log Out"
            onClick={() => {
                signOut();
            }}
            className={`${className} w-full cursor-pointer`}
        >
            Logout
        </div>
    );
}
