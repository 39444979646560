"use client";

import { findOrCreateCartAndSetCookie } from "@/components/features/cart-modal/actions";
import { Button } from "@/components/shared/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/shared/ui/popover";
import { Skeleton } from "@/components/shared/ui/skeleton";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/shared/ui/tooltip";
import { ShopCustomer } from "@/lib/customers/customer-types";
import { useCurrentCustomer } from "@/state-management/context/current-customer-context";
import { ChevronDown, Heart, ShoppingCart, Store, User } from "lucide-react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useState } from "react";

interface CurrentCustomerProps {
    favorites: ShopCustomer[];
    currentCustomer?: ShopCustomer;
    singleMode?: boolean;
}

export default function CurrentCustomer({
    favorites = [],
    currentCustomer,
    singleMode = false
}: CurrentCustomerProps) {
    const [isMounted, setIsMounted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { update } = useSession();
    const { customer, setCurrentCustomer } = useCurrentCustomer();
    const router = useRouter();

    useEffect(() => {
        setIsMounted(true);
        if (currentCustomer) {
            setCurrentCustomer(currentCustomer);
        }
    }, [currentCustomer, setCurrentCustomer]);

    const handleCustomerClick = useCallback(
        async (favorite: ShopCustomer) => {
            setIsOpen(false);
            try {
                const newSession = await update({
                    currentCustomerID: favorite.customerID,
                    defaultStateCode: favorite.state
                });

                if (newSession?.currentCustomerID === favorite.customerID) {
                    setCurrentCustomer(favorite);
                    await findOrCreateCartAndSetCookie();
                } else {
                    throw new Error("Session update failed");
                }
            } catch (error) {
                console.error("Error updating session:", error);
                alert("An error occurred while updating session");
            }
        },
        [update, setCurrentCustomer]
    );

    const handleBrowseCustomersClick = (
        event: React.MouseEvent<HTMLAnchorElement>,
        href: string
    ) => {
        event.preventDefault();
        setIsOpen(false);
        setTimeout(() => {
            router.push(href);
        }, 25);
    };

    if (!isMounted) {
        return (
            <div className="flex flex-col space-y-2">
                <Skeleton className="h-6 w-48" />
                <Skeleton className="h-4 w-24" />
            </div>
        );
    }

    const sortedFavorites = [...favorites].sort((a, b) => {
        const cartCompare = (b.pendingCartQuantity ?? 0) - (a.pendingCartQuantity ?? 0);
        if (cartCompare) return cartCompare;

        const stateCompare = a.state.toLowerCase().localeCompare(b.state.toLowerCase());
        if (stateCompare) return stateCompare;

        const nameCompare = a.customerName
            .toLowerCase()
            .localeCompare(b.customerName.toLowerCase());
        return nameCompare;
    });

    const currentContent = customer ? (
        <div className="flex items-center gap-2 text-sm">
            <Store className="h-4 w-4 text-gray-500" aria-hidden="true" />
            <span className="font-medium">
                <span className="xs:text-md text-sm text-gray-500">({customer.customerID})</span>{" "}
                {`${customer.customerName.substring(0, 29)}${customer.customerName.length > 30 ? "..." : ""}`}
                <span className="ml-1 text-gray-500">• {customer.state}</span>
            </span>
        </div>
    ) : (
        <div className="flex items-center gap-1.5 text-sm">
            <User className="h-4 w-4" />
            Select Customer
        </div>
    );

    return singleMode ? (
        <>{currentContent}</>
    ) : (
        <div className="flex flex-col">
            <Popover open={isOpen} onOpenChange={setIsOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="ghost"
                        className="flex w-fit flex-row gap-1.5 p-2 hover:bg-gray-100"
                        size="lg"
                    >
                        {currentContent}
                        <ChevronDown
                            className={`h-4 w-4 transition-transform ${isOpen ? "rotate-180" : ""}`}
                            aria-hidden="true"
                        />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="mx-4 my-0 h-auto w-auto p-0">
                    <div className="flex max-h-[65vh] w-fit max-w-[90vw] flex-col overflow-hidden">
                        <div className="flex flex-none items-center gap-6 border-b px-4 py-2 text-sm font-medium text-gray-600">
                            <Heart className="h-4 w-4 flex-shrink-0" />
                            Favorite Customers
                        </div>

                        <div className="flex-initial overflow-y-auto">
                            {sortedFavorites.length > 0 ? (
                                sortedFavorites.map((favorite) => (
                                    <button
                                        key={favorite.customerID}
                                        onClick={async () => {
                                            const oldState = customer?.state;
                                            await handleCustomerClick(favorite);
                                            if (oldState !== favorite?.state) {
                                                // have to reload the page to change the state in the url
                                                window.location.reload();
                                            }
                                        }}
                                        className={`flex w-full items-center justify-between px-4 py-1.5 text-left hover:bg-gray-100 ${customer?.customerID === favorite.customerID ? "bg-gray-100" : ""}`}
                                    >
                                        <div className="flex items-center gap-2 pr-2 text-sm">
                                            <span className="flex-shrink-0 text-gray-500">
                                                {favorite.state}
                                            </span>
                                            <span className="w-12 min-w-6 flex-shrink-0 text-right text-xs text-gray-500">
                                                ({favorite.customerID})
                                            </span>
                                            <span>{favorite.customerName}</span>
                                        </div>
                                        {favorite.pendingCartQuantity !== undefined &&
                                            favorite.pendingCartQuantity > 0 && (
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <div className="flex items-center gap-1 text-xs text-cart">
                                                            <ShoppingCart className="h-3 w-3" />
                                                            <span>
                                                                {favorite.pendingCartQuantity}
                                                            </span>
                                                        </div>
                                                    </TooltipTrigger>
                                                    <TooltipContent>
                                                        <p>
                                                            You have {favorite.pendingCartQuantity}{" "}
                                                            {favorite.pendingCartQuantity === 1
                                                                ? "case "
                                                                : "cases "}
                                                            in the cart for this customer
                                                        </p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            )}
                                    </button>
                                ))
                            ) : (
                                <div className="px-4 py-1.5 text-left text-sm font-light italic text-gray-400">
                                    No favorite customers yet.
                                    <br />
                                    You can add some from the customer list.
                                </div>
                            )}
                        </div>
                        <div className="flex-shrink-0">
                            <Button
                                asChild
                                variant="ghost"
                                className="w-full justify-start gap-6 rounded-t-none border-t hover:rounded-t-none"
                                onClick={() => setIsOpen(false)}
                            >
                                <a
                                    href="/select_customer"
                                    onClick={(event) => {
                                        handleBrowseCustomersClick(event, "/select_customer");
                                    }}
                                >
                                    <Store className="h-4 w-4" />
                                    <span className="text-sm">Browse All Customers</span>
                                </a>
                            </Button>
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
}
