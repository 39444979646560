"use client";

import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger
} from "@/components/shared/ui/collapsible";
import { GroupedTransactions, ShopCartTransaction } from "@/lib/cart/cart-types";
import { ManualPromotion } from "@/lib/shop/shop-types";
import { ChevronDown, ChevronUp } from "lucide-react";
import { memo, useMemo, useState } from "react";
import { ProductHeader } from "./product-header";
import { TransactionsList } from "./transactions-list";

const MemoizedTransactionsList = memo(TransactionsList);

const useGroupedTransactions = (transactions: ShopCartTransaction[]): GroupedTransactions => {
    return useMemo(() => {
        return transactions.reduce<GroupedTransactions>((acc, transaction) => {
            const productID = transaction.productID;

            if (!acc[productID]) {
                acc[productID] = {
                    productID: transaction.productID,
                    productName: transaction.productName,
                    thumbnailImageURL: transaction.productThumbnailImageURL,
                    wholesaleUnitsPerCase: transaction.wholesaleUnitsPerCase,
                    sellByCaseOnly: transaction.sellByCaseOnly,
                    transactions: [],
                    totalUnits: 0,
                    totalFullPrice: 0,
                    totalExtPrice: 0,
                    totalDiscount: 0,
                    inUsePromotions: [],
                    backorderUnits: 0,
                    hasBaseTransaction: false,
                    state: transaction.productState,
                    minOrderUnits: transaction.minOrderUnits
                };
            }

            acc[productID]!.transactions.push(transaction);
            acc[productID]!.totalUnits += Number(transaction.numUnits);
            acc[productID]!.totalExtPrice += Number(transaction.extPrice);
            acc[productID]!.totalFullPrice +=
                (Number(transaction.unitPrice) + Number(transaction.discount)) *
                transaction.numUnits;
            acc[productID]!.totalDiscount += Number(transaction.discount * transaction.numUnits);
            acc[productID]!.backorderUnits += Number(transaction.backorderNumUnits);

            if (transaction.manualPromotionID) {
                acc[productID]!.inUsePromotions.push(transaction.manualPromotionID);
            } else {
                acc[productID]!.hasBaseTransaction = true;
            }

            return acc;
        }, {});
    }, [transactions]);
};

const ProductGroup = memo(function ProductGroup({
    group,
    productPromotions
}: {
    productID: string;
    group: GroupedTransactions[keyof GroupedTransactions];
    productPromotions: { [key: number]: ManualPromotion[] } | null;
}) {
    const [isOpen, setIsOpen] = useState(false);

    const manualPromotionsList = useMemo(() => {
        if (!productPromotions) return null;
        const promotions = productPromotions[group.productID];
        return promotions ? promotions : [];
    }, [productPromotions, group.productID]);

    return (
        <div className={`space-y-4`}>
            <Collapsible open={isOpen} onOpenChange={setIsOpen} className="w-full px-2">
                <ProductHeader group={group} />

                <CollapsibleTrigger
                    className={`text-md ${group.backorderUnits > 0 ? "border-b-backorder" : "border-b-cart "} my-1 flex w-full flex-row items-center justify-between border-b-2 px-4 py-1 hover:bg-accent`}
                >
                    <span>
                        {group.transactions.length} Transaction
                        {group.transactions.length > 1 ? "s" : ""}
                    </span>
                    <div className="flex flex-row items-center gap-2">
                        {isOpen ? <span>Click to close</span> : <span>Click to edit</span>}
                        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                    </div>
                </CollapsibleTrigger>

                <CollapsibleContent className="w-full pl-6">
                    <MemoizedTransactionsList
                        transactions={group.transactions}
                        productID={group.productID}
                        productName={group.productName}
                        wholesaleUnitsPerCase={group.wholesaleUnitsPerCase}
                        sellByCaseOnly={group.sellByCaseOnly}
                        manualPromotionsList={manualPromotionsList}
                        minOrderUnits={group.minOrderUnits}
                    />
                </CollapsibleContent>
            </Collapsible>
        </div>
    );
});

export default function TransactionsListWithHeader({
    transactions,
    productPromotions
}: {
    transactions: ShopCartTransaction[];

    productPromotions: { [key: number]: ManualPromotion[] } | null;
}) {
    const transactionGroups = useGroupedTransactions(transactions);

    return (
        <div className="space-y-8">
            {Object.entries(transactionGroups).map(([productID, group]) => {
                return (
                    <ProductGroup
                        key={productID}
                        productID={productID}
                        group={group}
                        productPromotions={productPromotions}
                    />
                );
            })}
        </div>
    );
}
